import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Reaptcha from 'reaptcha'

import { Language } from '../models/Language'

const Contact = () => {
  const { t, i18n } = useTranslation()
  const captchaRef = useRef<Reaptcha>(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')

  const [isVerified, setIsVerified] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState<boolean | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const subjects = t<string, string[]>('contact.subjects', { returnObjects: true })

  const verifyCaptcha = (response: string) => {
    console.log('reCAPTCHA response: ', response)
    setIsVerified(true)
  }

  const sendMessage = async (event: React.SyntheticEvent) => {
    event.preventDefault()

    // TODO: Use an enum for language
    const language = i18n.resolvedLanguage.includes(Language.English) ? 1 : 2
    const url = `${process.env.REACT_APP_API_URL}/common/contactus`

    setIsLoading(true)
    setIsMessageSent(undefined)

    const contact = {
      name,
      email,
      subject,
      message,
      language,
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then((res) => {
        setIsMessageSent(res.ok)
        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
      })
      .catch((err) => {
        console.error('Error: ', err)
        setIsMessageSent(false)
      })
      .finally(() => {
        captchaRef.current?.reset()
        setIsVerified(false)
        setIsLoading(false)
      })
  }

  return (
    <section id="contact" className="contact-area bg-gray ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2 className="text-capitalize">{t('contact.title')}</h2>
              <p className="mt-4">
                <div>
                  <a href={`mailto:${process.env.REACT_APP_EMAIL}?subject=${t('contact.title')}`}>
                    <i className="fas fa-envelope"></i> {process.env.REACT_APP_EMAIL}
                  </a>
                </div>
                <div>
                  <a href={`tel:${process.env.REACT_APP_PHONE}`}>
                    <i className="fas fa-phone"></i> {process.env.REACT_APP_PHONE}
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 pt-4 pt-md-0">
            <div className="text-center">
              {isMessageSent === true && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  <h4 className="alert-heading">{t('contact.received')}</h4>
                  {t('contact.thank')}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  />
                </div>
              )}
              {isMessageSent === false && (
                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                  <h4 className="alert-heading">{t('error.title')}</h4>
                  {t('error.message')}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  />
                </div>
              )}
              <form onSubmit={sendMessage}>
                <div className="row">
                  <div className="col mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder={t('contact.name')}
                      required
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder={t('contact.email')}
                      required
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <select
                      className="form-select"
                      required
                      onChange={(event) => setSubject(event.target.value)}
                      value={subject}
                    >
                      <option value="">{t('contact.subject')}</option>
                      {subjects.map((item, index) => (
                        <option key={`subject-${index}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder={t('contact.message')}
                      required
                      value={message}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Reaptcha
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onVerify={verifyCaptcha}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-lg btn-block mt-3"
                      disabled={!isVerified || isLoading}
                    >
                      <span className="pe-3">
                        {isLoading ? (
                          <i className="fa-solid fa-circle-notch fa-spin"></i>
                        ) : (
                          <i className="fas fa-paper-plane"></i>
                        )}
                      </span>
                      {isLoading ? t('contact.sending') : t('contact.send')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
