import React from 'react'
import { useTranslation } from 'react-i18next'

const StripeConfirmationPage = () => {
  const { t } = useTranslation()

  return (
    <div className="h-100">
      <section id="stripe-confirmation" className="section ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="section-heading text-center">
                <i className="fa-solid fa-circle-check"></i>
                <h3>{t('stripe.title')}</h3>
                <p>{t('stripe.subtitle')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default StripeConfirmationPage
