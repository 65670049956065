import { useTranslation } from 'react-i18next'

const Murb = () => {
  const { t } = useTranslation()

  const driverAdvantages = t<string, string[]>('murb.driver.advantages', { returnObjects: true })
  const hostAdvantages = t<string, string[]>('murb.host.advantages', { returnObjects: true })

  return (
    <section id="pricing" className="section price-plan-area  overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2>{t('murb.how-it-works')}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-lg-8">
            <div className="row price-plan-wrapper">
              <div className="col-12 col-md-6">
                <div className="single-price-plan text-center p-5">
                  <div className="plan-thumb">
                    <i className="fa-regular fa-car-side"></i>
                  </div>
                  <div className="plan-title my-2 my-sm-3">
                    <h4 className="text-uppercase text-primary">{t('murb.driver')}</h4>
                  </div>
                  <div className="plan-description">
                    <ul className="plan-features list-unstyled">
                      {driverAdvantages.map((item, index) => (
                        <li key={`driver-advantage-${index}`} className="border-top py-3">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <div
                  className="single-price-plan text-center p-5 wow fadeInRight"
                  data-aos-duration="2s"
                  data-wow-delay="0.4s"
                >
                  <div className="plan-thumb">
                    <i className="fa-solid fa-charging-station"></i>
                  </div>
                  <div className="plan-title my-2 my-sm-3">
                    <h4 className="text-uppercase text-primary">{t('murb.host')}</h4>
                  </div>
                  <div className="plan-description">
                    <ul className="plan-features list-unstyled">
                      {hostAdvantages.map((item, index) => (
                        <li key={`host-advantage-${index}`} className="border-top py-3">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Murb
