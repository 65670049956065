import React from 'react'
import { useTranslation } from 'react-i18next'

const HowItWorks = () => {
  const { t } = useTranslation()

  return (
    <section id="how-it-works" className="section work-area bg-overlay overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="work-content text-center">
              <h2 className="text-white">{t('how-it-works.title')}</h2>
              <h3 className="text-primary bg-white mt-5 d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                {t('how-it-works.driver')}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-solid fa-map-location-dot"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.driver.step.1.title')}</h3>
              <p className="text-white">{t('how-it-works.driver.step.1.description')}</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-regular fa-calendar-check"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.driver.step.2.title')}</h3>
              <p className="text-white">{t('how-it-works.driver.step.2.description')}</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-solid fa-charging-station"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.driver.step.3.title')}</h3>
              <p className="text-white">{t('how-it-works.driver.step.3.description')}</p>
            </div>
          </div>
        </div>
        <hr />
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="work-content text-center">
              <h3 className="text-primary bg-white mt-5 d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                {t('how-it-works.host')}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-solid fa-plug-circle-plus"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.host.step.1.title')}</h3>
              <p className="text-white">{t('how-it-works.host.step.1.description')}</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-solid fa-handshake"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.host.step.2.title')}</h3>
              <p className="text-white">{t('how-it-works.host.step.2.description')}</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon text-white">
                <i className="fa-solid fa-hand-holding-dollar"></i>
              </div>
              <h3 className="text-white py-3">{t('how-it-works.host.step.3.title')}</h3>
              <p className="text-white">{t('how-it-works.host.step.3.description')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
