import React from 'react'
import { useTranslation } from 'react-i18next'

import easyToUse from '../assets/img/features/easyToUse.svg'
import payAsYouGo from '../assets/img/features/payAsYouGo.svg'
import flexibleBooking from '../assets/img/features/flexibleBooking.svg'

const Features = () => {
  const { t } = useTranslation()

  return (
    <section id="features" className="section features-area style-two overflow-hidden ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2>{t('features.title')}</h2>
              <p className="mt-4">{t('features.description')}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 res-margin">
            <div className="image-box text-center icon-1 p-5 wow fadeInLeft">
              <div className="featured-icon mb-3">
                <img src={easyToUse} alt={t('features.feature.1.title')} />
              </div>
              <div className="icon-text">
                <h3 className="mb-2">{t('features.feature.1.title')}</h3>
                <p>{t('features.feature.1.description')}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin">
            <div className="image-box text-center icon-1 p-5 wow fadeInUp">
              <div className="featured-icon mb-3">
                <img src={payAsYouGo} alt={t('features.feature.2.title')} />
              </div>
              <div className="icon-text">
                <h3 className="mb-2">{t('features.feature.2.title')}</h3>
                <p>{t('features.feature.2.description')}</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="image-box text-center icon-1 p-5 wow fadeInRight">
              <div className="featured-icon mb-3">
                <img src={flexibleBooking} alt={t('features.feature.3.title')} />
              </div>
              <div className="icon-text">
                <h3 className="mb-2">{t('features.feature.3.title')}</h3>
                <p>{t('features.feature.3.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
