import React from 'react'
import { SocialEvseModel } from '../models/SocialEvseModel'

import charger from '../assets/img/charger.svg'

import ccs from '../assets/img/connectors/CCS.svg'
import chademo from '../assets/img/connectors/CHAdeMo.svg'
import j1772 from '../assets/img/connectors/J1772.svg'
import nema515 from '../assets/img/connectors/NEMA515.svg'
import tesla from '../assets/img/connectors/Tesla.svg'

interface Props {
  evses: Array<SocialEvseModel>
}

const LocationStations = ({ evses }: Props) => {
  const getConnectorImage = (standard: string) => {
    switch (standard.toLowerCase()) {
      case 'ccs':
        return ccs
      case 'chademo':
        return chademo
      case 'j1772':
        return j1772
      case 'nema515':
        return nema515
      case 'tesla':
        return tesla
    }
  }

  return (
    <div className="stations">
      {evses.map((evse, index) => (
        <div key={`station-${index}`} className="station">
          <img src={charger} alt="Charger" />
          <div className="connectors">
            <h4>{evse.name}</h4>
            {evse.connectors.map((connector, index) => (
              <div key={`connector-${index}`} className="connector">
                <img src={getConnectorImage(connector.standard)} alt="Connector" />
                <span>{connector.standard}</span>
                <i className="fa-solid fa-circle" title="point"></i>
                <span>{connector.powerKW.toFixed(1)} kW</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LocationStations
