import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'

import { AvailableLanguages } from '../models/Language'

const FILE_NAME = 'privacy-policy.md'

const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation()
  const [text, setText] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    const getTranslatedTexts = async () => {
      let map: Map<string, string> = new Map()

      for (const lng of Object.keys(AvailableLanguages)) {
        await fetch(`locales/${lng}/${FILE_NAME}`)
          .then((r) => r.text())
          .then((text) => map.set(lng, text))
      }
      setText(map)
    }

    getTranslatedTexts()
  }, [])

  return (
    <section className="section section-markdown ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h1>{t('privacy.title')}</h1>
              <p className="mt-4">{t('privacy.subtitle')}</p>
            </div>
          </div>
        </div>
        <ReactMarkdown>{text.get(i18n.resolvedLanguage) ?? ''}</ReactMarkdown>
      </div>
    </section>
  )
}

export default PrivacyPolicy
