import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AvailableLanguages } from '../models/Language'
import logoBlack from '../assets/img/logo-black.svg'
import logoWhite from '../assets/img/logo-white.svg'
import logo2degrees from '../assets/img/logo-2degres.png'
import AppStoreBadge from './shared/AppStoreBadge'
import GooglePlayBadge from './shared/GooglePlayBadge'

interface Props {
  full?: boolean
}

const Footer = ({ full = true }: Props) => {
  const { t, i18n } = useTranslation()
  const [colorScheme, setColorScheme] = useState('light')
  const currentYear = new Date().getFullYear()

  const logo = useMemo(() => {
    return colorScheme === 'dark' ? logoWhite : logoBlack
  }, [colorScheme])

  useEffect(() => {
    const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)')
    setColorScheme(darkModePreference.matches ? 'dark' : 'light')

    darkModePreference.addEventListener('change', (event) => {
      setColorScheme(event.matches ? 'dark' : 'light')
    })
  }, [])

  return full ? (
    <footer id="footer" className="footer-area bg-slate">
      <div className="footer-top ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="footer-items">
                <img className="logo" src={logo} alt="Foresta Logo" />
                <p className="mt-2 mb-3">{t('footer.description')}</p>
                <div className="social-icons d-flex">
                  {process.env.REACT_APP_FACEBOOK_URL !== '' && (
                    <a
                      className="facebook"
                      href={process.env.REACT_APP_FACEBOOK_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-fw fa-facebook-f"></i>
                    </a>
                  )}
                  {process.env.REACT_APP_TWITTER_URL !== '' && (
                    <a
                      className="twitter"
                      href={process.env.REACT_APP_TWITTER_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-fw fa-twitter"></i>
                    </a>
                  )}
                  {process.env.REACT_APP_INSTAGRAM_URL !== '' && (
                    <a
                      className="instagram"
                      href={process.env.REACT_APP_INSTAGRAM_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  )}
                  {process.env.REACT_APP_LINKEDIN_URL !== '' && (
                    <a
                      className="linkedin"
                      href={process.env.REACT_APP_LINKEDIN_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa-brands fa-fw fa-linkedin-in"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">{t('footer.links')}</h3>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <a href="#faq">{t('footer.links.faq')}</a>
                  </li>
                  <li className="py-2">
                    <Link to="/terms-conditions">{t('footer.links.terms')}</Link>
                  </li>
                  <li className="py-2">
                    <Link to="/privacy-policy">{t('footer.links.privacy')}</Link>
                  </li>
                  <li className="py-2">
                    <Link to="/host-earnings">{t('footer.links.hostEarnings')}</Link>
                  </li>
                  <li className="py-2">
                    <a href="#contact">{t('footer.links.contact')}</a>
                  </li>
                  {/* <li className="py-2">
                    <a
                      href={`mailto:${process.env.REACT_APP_EMAIL}?subject=${t(
                        'footer.links.support'
                      )}`}
                    >
                      {t('footer.links.support')}
                    </a>
                  </li> */}
                  <li>
                    <br />
                    <div className="footer-language">
                      {Object.keys(AvailableLanguages).map((lng) => (
                        <button
                          key={lng}
                          className={i18n.resolvedLanguage === lng ? 'active' : ''}
                          type="button"
                          onClick={() => i18n.changeLanguage(lng)}
                        >
                          {AvailableLanguages[lng].nativeName}
                        </button>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">{t('footer.solutions')}</h3>
                <ul className="list-unstyled">
                  <li className="py-2">
                    <a href="#features">{t('menu.solutions.home')}</a>
                  </li>
                  <li className="py-2">
                    <Link to="/murb" target="_blank">
                      {t('menu.solutions.murb')}
                    </Link>
                  </li>
                  <li className="py-2">
                    <a
                      href={process.env.REACT_APP_FORESTA_PRO_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('menu.solutions.pro')}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="footer-items">
                <h3 className="footer-title mb-2">{t('footer.download')}</h3>
                <div className="store-buttons d-flex">
                  <AppStoreBadge />
                  <GooglePlayBadge />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
              <div className="col-12 col-md-6 copyright-left">
                <div>{t('footer.incubatedAt')}</div>
                <a href="https://2degres.com" target="_blank" rel="noreferrer">
                  <img className="logo" src={logo2degrees} alt="2degrees" />
                </a>
              </div>
              <div className="col-12 col-md-6 copyright-right">
                <div>© Copyright {currentYear}</div>
                <div>
                  {t('footer.madeWith')}{' '}
                  <i className="fa-solid fa-heart text-danger" title="love"></i> {t('footer.by')}{' '}
                  <a href={process.env.REACT_APP_OXYGENIZE_URL} target="_blank" rel="noreferrer">
                    Oxygenize
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  ) : (
    <footer id="footer" className="footer-area inner-footer bg-slate ptb_50 mt-auto">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            <div className="footer-items text-center">
              <Link to="/">
                <img className="logo" src={logo} alt="Foresta Logo" />
              </Link>
              <div className="copyright-area border-0 pt-3">
                <p>
                  {t('footer.madeWith')}{' '}
                  <i className="fa-solid fa-heart text-danger" title="love"></i> {t('footer.by')}{' '}
                  <a href={process.env.REACT_APP_OXYGENIZE_URL} target="_blank" rel="noreferrer">
                    Oxygenize
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
