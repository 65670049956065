import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'

import { AvailableLanguages } from '../models/Language'

const FILE_NAME = 'cancellation-policy.md'

const CancellationPolicy = () => {
  const { t, i18n } = useTranslation()
  const [text, setText] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    const getTranslatedTexts = async () => {
      let map: Map<string, string> = new Map()

      for (const lng of Object.keys(AvailableLanguages)) {
        await fetch(`locales/${lng}/${FILE_NAME}`)
          .then((r) => r.text())
          .then((text) => map.set(lng, text))
      }
      setText(map)
    }

    getTranslatedTexts()
  }, [])

  return (
    <>
      <p>
        <strong>{t('cancellationPolicy.title')}</strong>
      </p>
      <ReactMarkdown>{text.get(i18n.resolvedLanguage) ?? ''}</ReactMarkdown>
    </>
  )
}

export default CancellationPolicy
