import React from 'react'
import { useTranslation } from 'react-i18next'

import { Language } from '../../models/Language'
import googlePlayEn from '../../assets/img/badges/google-play-en.png'
import googlePlayFr from '../../assets/img/badges/google-play-fr.png'

const GooglePlayBadge = () => {
  const { i18n } = useTranslation()

  return (
    <a
      href={process.env.REACT_APP_GOOGLE_PLAY_URL}
      className="google-play-badge"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={i18n.resolvedLanguage === Language.French ? googlePlayFr : googlePlayEn}
        alt="Google Play"
      />
    </a>
  )
}

export default GooglePlayBadge
