import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Header from '../components/Header'
import Murb from '../components/Murb'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

const MurbPage = () => {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // scrool to element Id if using # links
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <div className="h-100">
      <Header page="murb" />
      <Murb />
      <Contact />
      <Footer full={false} />
    </div>
  )
}

export default MurbPage
