import React from 'react'
import { useTranslation } from 'react-i18next'

import { Language } from '../models/Language'
import i18n from '../i18n'

import screenshot1En from '../assets/img/screenshots/en/screenshot-1.png'
import screenshot2En from '../assets/img/screenshots/en/screenshot-2.png'
import screenshot3En from '../assets/img/screenshots/en/screenshot-3.png'
import screenshot4En from '../assets/img/screenshots/en/screenshot-4.png'

import screenshot1Fr from '../assets/img/screenshots/fr/screenshot-1.png'
import screenshot2Fr from '../assets/img/screenshots/fr/screenshot-2.png'
import screenshot3Fr from '../assets/img/screenshots/fr/screenshot-3.png'
import screenshot4Fr from '../assets/img/screenshots/fr/screenshot-4.png'

const ScreenShots = () => {
  const { t } = useTranslation()

  const screenshot1 = i18n.resolvedLanguage === Language.French ? screenshot1Fr : screenshot1En
  const screenshot2 = i18n.resolvedLanguage === Language.French ? screenshot2Fr : screenshot2En
  const screenshot3 = i18n.resolvedLanguage === Language.French ? screenshot3Fr : screenshot3En
  const screenshot4 = i18n.resolvedLanguage === Language.French ? screenshot4Fr : screenshot4En

  return (
    <section id="screenshots" className="section screenshots-area bg-gray ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2>{t('screenshots.title')}</h2>
              <p className="mt-4">{t('screenshots.description')}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col pb-3 text-center">
            <h5 className="mb-2">{t('screenshots.1.title')}</h5>
            <figure className="figure text-center">
              <img
                src={screenshot1}
                className="figure-img img-screenshot img-fluid rounded shadow"
                alt={t('screenshots.1.title')}
              />
              <figcaption className="figure-caption">{t('screenshots.1.description')}</figcaption>
            </figure>
          </div>
          <div className="col pb-3 text-center">
            <h5 className="mb-2">{t('screenshots.2.title')}</h5>
            <figure className="figure text-center">
              <img
                src={screenshot2}
                className="figure-img img-screenshot img-fluid rounded shadow"
                alt={t('screenshots.2.title')}
              />
              <figcaption className="figure-caption">{t('screenshots.2.description')}</figcaption>
            </figure>
          </div>
          <div className="col pb-3 text-center">
            <h5 className="mb-2">{t('screenshots.3.title')}</h5>
            <figure className="figure text-center">
              <img
                src={screenshot3}
                className="figure-img img-screenshot img-fluid rounded shadow"
                alt={t('screenshots.3.title')}
              />
              <figcaption className="figure-caption">{t('screenshots.3.description')}</figcaption>
            </figure>
          </div>
          <div className="col pb-3 text-center">
            <h5 className="mb-2">{t('screenshots.4.title')}</h5>
            <figure className="figure text-center">
              <img
                src={screenshot4}
                className="figure-img img-screenshot img-fluid rounded shadow"
                alt={t('screenshots.4.title')}
              />
              <figcaption className="figure-caption">{t('screenshots.4.description')}</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScreenShots
