import React from 'react'

import Header from '../components/Header'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Footer from '../components/Footer'

const PrivacyPolicyPage = () => {
  return (
    <div className="h-100">
      <Header full={false} />
      <PrivacyPolicy />
      <Footer full={false} />
    </div>
  )
}

export default PrivacyPolicyPage
