import React from 'react'
import { useParams } from 'react-router-dom'

import Header from '../components/Header'
import Evse from '../components/Evse'
import Footer from '../components/Footer'

const EvsePage = () => {
  let params = useParams()

  return (
    <div className="h-100">
      <Header full={true} />
      <Evse code={params.code} />
      <Footer full={true} />
    </div>
  )
}

export default EvsePage
