import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhotoModel } from '../models/PhotoModel'

interface Props {
  photos: Array<PhotoModel>
}

const LocationPhotosGallery = ({ photos }: Props) => {
  const { t } = useTranslation()

  return (
    <div id="photosCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        {photos.map((photo, index) => (
          <button
            key={`indicator-${index}`}
            type="button"
            data-bs-target="#photosCarousel"
            data-bs-slide-to={index}
            className={`${photo.isMain ? 'active' : ''}`}
            aria-current="true"
            aria-label={`Slide ${index}`}
          />
        ))}
      </div>
      <div className="carousel-inner">
        {photos.map((photo) => (
          <div
            key={`photo-${photo.id}`}
            className={`carousel-item ${photo.isMain ? 'active' : ''}`}
          >
            <img
              src={`${process.env.REACT_APP_COMMON_STORAGE_URI}${photo.url}`}
              className="d-block w-100"
              alt="..."
            />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#photosCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">{t('photos.prev')}</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#photosCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">{t('photos.next')}</span>
      </button>
    </div>
  )
}

export default LocationPhotosGallery
