import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS, isMacOs } from 'react-device-detect'

import { SocialLocationModel } from '../models/SocialLocationModel'
import LocationStations from './LocationStations'
import LocationPhotosGallery from './LocationPhotosGallery'

interface Props {
  id: string | undefined
}

const Location = ({ id }: Props) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [location, setLocation] = useState<SocialLocationModel | undefined>(undefined)

  useEffect(() => {
    const fetchLocation = async (id: string) => {
      const url = `${process.env.REACT_APP_API_URL}/Locations/social/${id}`

      await fetch(url)
        .then((res) => res.json())
        .then((data) => {
          if (data.message) {
            setSuccess(false)
            setErrorMessage(data.message)
          } else {
            setSuccess(true)
            setLocation(data)
          }
        })
        .catch((err) => {
          console.error('Error: ', err)
          setSuccess(false)
        })
    }

    if (id) fetchLocation(id)
  }, [id])

  const fullAdress = useMemo(() => {
    if (location === undefined) return ''
    return `${location.address}, ${location.city}, ${location.state}, ${location.postalCode}`
  }, [location])

  const bookURrl = useMemo(() => {
    if (location === undefined) return undefined
    if (isIOS || isAndroid) return `foresta://explore/locations/${id}`
    return process.env.REACT_APP_APP_STORE_URL
  }, [location, id])

  const mapUrl = useMemo(() => {
    if (location === undefined) return undefined
    if (isIOS || isMacOs) return `http://maps.apple.com/?address=${fullAdress}`
    return `https://maps.google.com/maps?q=${fullAdress}`
  }, [location, fullAdress])

  return (
    <section id="location" className="section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col">
            <div className="section-heading text-center">
              {success === undefined && (
                <div className="spinner-border text-primary mt-5" role="status">
                  <span className="visually-hidden">{t('loading')}</span>
                </div>
              )}
              {success === true && location !== undefined && (
                <>
                  <h1>{location.name}</h1>
                  <p className="mt-4">
                    <a href={mapUrl}>{fullAdress}</a>
                  </p>
                </>
              )}
              {success === false && (
                <div className="alert alert-danger" role="alert">
                  <h4 className="alert-heading">{t('error.title')}</h4>
                  <p>{errorMessage}</p>
                  <p>{t('error.message')}</p>
                </div>
              )}
            </div>
            {success === true && location !== undefined && (
              <div className="location">
                <div className="row gap-2 gap-md-0">
                  <div className="col-12 col-md-6">
                    <LocationPhotosGallery photos={location.photos} />
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="map-area">
                      <iframe
                        title={t('location.map')}
                        src={`https://maps.google.com/maps?q=${fullAdress}&t=&z=16&ie=UTF8&output=embed`}
                        height={100}
                        style={{ border: 0 }}
                        allowFullScreen
                      />
                    </div>
                  </div>

                  <LocationStations evses={location.evses} />
                </div>

                {bookURrl !== undefined && (
                  <a href={bookURrl} className="btn btn-book">
                    {t('location.book')}
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Location
