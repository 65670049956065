import React from 'react'
import { useParams } from 'react-router-dom'

import Header from '../components/Header'
import Unsubscribe from '../components/Unsubscribe'
import Footer from '../components/Footer'

const UnsubscribePage = () => {
  let params = useParams()

  return (
    <div className="d-flex flex-column h-100">
      <Header full={false} />
      <Unsubscribe code={params.code} />
      <Footer full={false} />
    </div>
  )
}

export default UnsubscribePage
