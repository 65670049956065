import React from 'react'

import Header from '../components/Header'
import Terms from '../components/Terms'
import Footer from '../components/Footer'

const TermsConditionsPage = () => {
  return (
    <div className="h-100">
      <Header full={false} />
      <Terms />
      <Footer full={false} />
    </div>
  )
}

export default TermsConditionsPage
