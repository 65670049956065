import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  code: string | undefined
}

const Unsubscribe = ({ code }: Props) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const unsubscribe = async (code: string) => {
      const url = `${process.env.REACT_APP_API_URL}/common/unsubscribe/${code}`
      const options = { method: 'POST' }

      await fetch(url, options)
        .then((res) => {
          setSuccess(res.ok)
        })
        .catch((err) => {
          console.error('Error: ', err)
          setSuccess(false)
        })
    }

    if (code) unsubscribe(code)
  }, [code])

  return (
    <section id="terms-conditions" className="section ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h1>{t('unsubscribe.title')}</h1>
              {success === undefined && (
                <div className="spinner-border text-primary mt-5" role="status">
                  <span className="visually-hidden">{t('loading')}</span>
                </div>
              )}
              {success === true && <p className="mt-4">{t('unsubscribe.subtitle')}</p>}
              {success === false && (
                <div className="alert alert-danger" role="alert">
                  <h4 className="alert-heading">{t('error.title')}</h4>
                  {t('error.message')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Unsubscribe
