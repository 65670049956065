import React from 'react'

import Header from '../components/Header'
import Contact from '../components/Contact'
import Footer from '../components/Footer'

const ContactPage = () => {
  return (
    <div className="h-100">
      <Header full={false} />
      <Contact />
      <Footer full={false} />
    </div>
  )
}

export default ContactPage
