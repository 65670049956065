import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isAndroid, isIOS } from 'react-device-detect'

interface Props {
  code: string | undefined
}

const Evse = ({ code }: Props) => {
  const { t } = useTranslation()
  const [willRedirect, setWillRedirect] = useState(true)

  const isMobile = isIOS || isAndroid

  const getStoreLink = (): string | undefined => {
    if (isIOS) return process.env.REACT_APP_APP_STORE_URL
    if (isAndroid) return process.env.REACT_APP_GOOGLE_PLAY_URL
    return undefined
  }

  const getAppLink = (): string | undefined => {
    if (isMobile) {
      return `foresta://explore/evses/${code}`
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const url = getAppLink()
      if (url) window.location.href = url
    }, 2000)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const url = getStoreLink()
      if (url) window.location.href = url
      setWillRedirect(false)
    }, 5000)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className="section">
      <div className="container">
        {isMobile && willRedirect ? (
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="section-heading text-center">
                <h2>{t('loading')}</h2>
                <p className="mt-4">{t('redirecting')}</p>
                <div className="spinner-border text-primary mt-5" role="status">
                  <span className="visually-hidden">{t('loading')}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="section-heading text-center">
                <h2>{t('features.title')}</h2>
                <p className="mt-4">{t('features.feature.1.description')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Evse
