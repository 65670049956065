import React from 'react'
import { useTranslation } from 'react-i18next'

import { Language } from '../../models/Language'
import appStoreEn from '../../assets/img/badges/app-store-en.svg'
import appStoreFr from '../../assets/img/badges/app-store-fr.svg'

const AppStoreBadge = () => {
  const { i18n } = useTranslation()

  return (
    <a
      href={process.env.REACT_APP_APP_STORE_URL}
      className="app-store-badge"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={i18n.resolvedLanguage === Language.French ? appStoreFr : appStoreEn}
        alt="App Store"
      />
    </a>
  )
}

export default AppStoreBadge
