import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import '../App.css'

import Header from '../components/Header'
import HowItWorks from '../components/HowItWorks'
import Features from '../components/Features'
import Faq from '../components/Faq'
import ScreenShots from '../components/ScreenShots'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

const App = () => {
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // scrool to element Id if using # links
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])

  return (
    <div className="h-100">
      <Header />
      <Features />
      <HowItWorks />
      <ScreenShots />
      <Faq />
      <Contact />
      <Footer />
    </div>
  )
}

export default App
