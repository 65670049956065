import React from 'react'

const UniversalLinksPage = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="justify-content-center mt-3">
          <div className="section-heading text-center">
            <h2 className="text-capitalize">Universal Links</h2>
          </div>
        </div>

        <ul className="list-group my-4">
          <li className="list-group-item">
            <a href="foresta://foresta.energy/explore">foresta://foresta.energy/explore</a>
          </li>
          <li className="list-group-item">
            <a href="foresta://foresta.energy/dashboard">foresta://foresta.energy/dashboard</a>
          </li>
          <li className="list-group-item">
            <a href="foresta://foresta.energy/host">foresta://foresta.energy/host</a>
          </li>
          <li className="list-group-item">
            <a href="foresta://foresta.energy/account">foresta://foresta.energy/account</a>
          </li>
          <li className="list-group-item">
            <a href="foresta://foresta.energy/explore/locations/6510b53c-69a9-4b3f-b116-2b64b03d0fd2">
              foresta://foresta.energy/explore/locations/6510b53c-69a9-4b3f-b116-2b64b03d0fd2
            </a>
          </li>
          <li className="list-group-item">
            <a href="foresta://foresta.energy/dashboard/contracts/90b54840-4aa6-4b81-95e0-3601fd6eeaec">
              foresta://foresta.energy/dashboard/contracts/90b54840-4aa6-4b81-95e0-3601fd6eeaec
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default UniversalLinksPage
