import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AvailableLanguages, Language } from '../models/Language'

import logoWhite from '../assets/img/logo-white.svg'

import phoneEn from '../assets/img/welcome/mockup-en.png'
import phoneFr from '../assets/img/welcome/mockup-fr.png'
import AppStoreBadge from './shared/AppStoreBadge'
import GooglePlayBadge from './shared/GooglePlayBadge'

interface Props {
  full?: boolean
  page?: string
}

const Header = ({ full = true, page = undefined }: Props) => {
  const { t, i18n } = useTranslation()

  if (page === 'murb') {
    return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img className="navbar-brand-regular" src={logoWhite} alt="Foresta Logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto me-5">
                  <li>
                    <div className="footer-language">
                      {i18n.resolvedLanguage === Language.English ? (
                        <button
                          className="nav-link"
                          onClick={() => i18n.changeLanguage(Language.French)}
                        >
                          {AvailableLanguages[Language.French].nativeName}
                        </button>
                      ) : (
                        <button
                          className="nav-link"
                          onClick={() => i18n.changeLanguage(Language.English)}
                        >
                          {AvailableLanguages[Language.English].nativeName}
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <section
          id="home"
          className="section welcome-area murb-area bg-overlay overflow-hidden d-flex align-items-center"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="welcome-intro">
                  <strong className="text-white">{t('menu.solutions.murb')}</strong>
                  <h1 className="text-white">{t('murb.title')}</h1>
                  <p className="text-white my-4">{t('murb.subtitle')}</p>
                  <div className="store-buttons d-flex">
                    <AppStoreBadge />
                    <GooglePlayBadge />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-bottom">
            <svg
              viewBox="0 0 1920 310"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className="svg replaced-svg"
            >
              <title>Shape</title>
              <desc>Created with Sketch</desc>
              <defs></defs>
              <g
                id="sApp-Landing-Page"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="sApp-Landing-Page-g" transform="translate(0.000000, -554.000000)">
                  <path
                    d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                    id="sApp-Landing-Page-g-path"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </section>
      </>
    )
  }

  return full ? (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img className="navbar-brand-regular" src={logoWhite} alt="Foresta Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto me-5">
                <li className="nav-item dropdown">
                  <a
                    href="/"
                    id="navbarDropdownMenuLink"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup={true}
                    aria-expanded={false}
                  >
                    {t('menu.solutions')}
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <li>
                      <a className="dropdown-item" href="#features">
                        {t('menu.solutions.home')}
                      </a>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/murb" target="_blank">
                        {t('menu.solutions.murb')}
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={process.env.REACT_APP_FORESTA_PRO_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('menu.solutions.pro')}
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#how-it-works">
                    {t('menu.how-it-works')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#faq">
                    {t('menu.faq')}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">
                    {t('menu.contact')}
                  </a>
                </li>
                <li>
                  <div className="footer-language">
                    {i18n.resolvedLanguage === Language.English ? (
                      <button
                        className="nav-link"
                        onClick={() => i18n.changeLanguage(Language.French)}
                      >
                        {AvailableLanguages[Language.French].nativeName}
                      </button>
                    ) : (
                      <button
                        className="nav-link"
                        onClick={() => i18n.changeLanguage(Language.English)}
                      >
                        {AvailableLanguages[Language.English].nativeName}
                      </button>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section
        id="home"
        className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 col-lg-6">
              <div className="welcome-intro">
                <strong className="text-white">{t('menu.solutions.home')}</strong>
                <h1 className="text-white">{t('title')}</h1>
                <p className="text-white my-4">{t('subtitle')}</p>
                <div className="store-buttons d-flex">
                  <AppStoreBadge />
                  <GooglePlayBadge />
                </div>
                <span className="d-inline-block text-white fw-3 font-italic mt-3">
                  {t('availability')}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6">
              <div className="welcome-thumb mx-auto aos-init aos-animate">
                <img
                  src={i18n.resolvedLanguage === Language.French ? phoneFr : phoneEn}
                  alt={t('phone')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="shape-bottom">
          <svg
            viewBox="0 0 1920 310"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className="svg replaced-svg"
          >
            <title>Shape</title>
            <desc>Created with Sketch</desc>
            <defs></defs>
            <g id="sApp-Landing-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="sApp-Landing-Page-g" transform="translate(0.000000, -554.000000)">
                <path
                  d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                  id="sApp-Landing-Page-g-path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </section>
    </>
  ) : (
    <header className="sticky-top">
      <nav className="navbar position-relative bg-overlay navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img className="navbar-brand-small" src={logoWhite} alt="Foresta Logo" />
          </a>
          <ul className="navbar-nav ms-auto">
            <li>
              <div className="footer-language">
                {i18n.resolvedLanguage === Language.English ? (
                  <button className="nav-link" onClick={() => i18n.changeLanguage(Language.French)}>
                    {AvailableLanguages[Language.French].nativeName}
                  </button>
                ) : (
                  <button
                    className="nav-link"
                    onClick={() => i18n.changeLanguage(Language.English)}
                  >
                    {AvailableLanguages[Language.English].nativeName}
                  </button>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
