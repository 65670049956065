import React from 'react'

import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import Location from '../components/Location'
import Footer from '../components/Footer'

const LocationPage = () => {
  let params = useParams()

  return (
    <div className="h-100">
      <Header />
      <Location id={params.id?.toLowerCase()} />
      <Footer />
    </div>
  )
}

export default LocationPage
