import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type FaqQuestion = {
  question: string
  answer: string
}

const Faq = () => {
  const { t, i18n } = useTranslation()
  const [search, setSearch] = useState<string>('')

  const questions = t<string, FaqQuestion[]>('faq.questions', { returnObjects: true })

  i18n.on('languageChanged', () => {
    setSearch('')
  })

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value
    setSearch(searchText)
  }

  return (
    <section id="faq" className="section faq-area ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2 className="text-capitalize">{t('faq.title')}</h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 mb-3">
            <input
              type="search"
              value={search}
              className="form-control"
              placeholder={t('faq.search')}
              onChange={onSearch}
            />
          </div>
        </div>

        <div className="accordion accordion-flush" id="faq-accordion">
          {questions
            .filter(
              (question) =>
                question.question.toLowerCase().includes(search.toLowerCase()) ||
                question.answer.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, index) => (
              <div className="accordion-item" key={`faq-${index}`}>
                <h2 className="accordion-header" id={`accordion-header-${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#accordion-collapse-${index}`}
                    aria-expanded="false"
                    aria-controls={`accordion-collapse-${index}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={`accordion-collapse-${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`accordion-header-${index}`}
                  data-bs-parent="#faq-accordion"
                >
                  <div className="accordion-body">{item.answer}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Faq
