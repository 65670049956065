import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <section
      id="not-found"
      className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden"
    >
      <div className="container h-100">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-12 col-md-7">
            <div className="welcome-intro error-area text-center">
              <h1 className="text-white">404</h1>
              <p className="text-white my-4">{t('error.notFound')}</p>
              <a href="/" className="btn sApp-btn text-uppercase">
                {t('error.goHome')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotFoundPage
